import { Location } from 'history';
import qs from 'qs';

import { ThirdPartyLoginError } from '@/types/authentication';

export interface Query {
  token?: string;
  errorType?: ThirdPartyLoginError;
  errorMessage?: string;
  provider: 'linkedin';
}

export const parseQuery = (location: Location): Query => {
  const params = qs.parse(location.search.slice(1));

  return {
    token: params.token,
    errorType: params.error_type,
    errorMessage: params.error_message,
    provider: params.provider ?? 'linkedin',
  } as Query;
};
