import { useCallback } from 'react';

import { setAuthToken } from '@/api/api';
import { authenticate, signup } from '@/api/authentication';
import { storeAuthToken } from '@/authentication';
import { SignUpAttributes } from '@/types/authentication';

import { useAuthenticationDispatch } from '../providers/authentication.context';

type SignupHook = (attributes: SignUpAttributes) => Promise<void>;

export function useSignup(): SignupHook {
  const dispatch = useAuthenticationDispatch();

  return useCallback(
    async (attributes: SignUpAttributes) => {
      await signup(attributes);
      const token = await authenticate({
        email: attributes.email,
        password: attributes.password,
      });

      storeAuthToken(token);
      setAuthToken(token);

      dispatch({ type: 'LOGGED_IN' });
    },
    [dispatch]
  );
}
