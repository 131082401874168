import React from 'react';

import { useHistory } from '@/hooks/router';

import { useHandleCallback } from './callback.hooks';
import { parseQuery } from './callback.utils';

export function Callback() {
  const { location } = useHistory();
  const handleCallback = useHandleCallback();

  React.useEffect(() => {
    const query = parseQuery(location);
    handleCallback(query);
  }, [handleCallback, location]);

  return null;
}
