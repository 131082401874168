import React from 'react';
import qs from 'qs';

import { useNotifications } from '@/features/notifications';
import { useHistory } from '@/hooks/router';

import { useSaveAuthToken } from '../../hooks';

import { Query } from './callback.utils';

export const useHandleCallback = () => {
  const history = useHistory();
  const { addNotification } = useNotifications();
  const saveAuthToken = useSaveAuthToken();

  return React.useCallback(
    async (query: Query) => {
      if (query.token != null) {
        saveAuthToken(query.token);
        history.replace('/admin');
      } else if (query.errorType != null) {
        if (query.errorType === 'UserNotFound') {
          history.replace({
            pathname: '/admin',
            search: qs.stringify({
              error: 'UserNotFound',
              provider: query.provider,
            }),
          });
        } else if (query.errorType === 'EmailNotProvided') {
          addNotification({
            type: 'third_party_login_error_email_not_provided',
            meta: { provider: query.provider },
          });
        } else if (query.errorType === 'UserNotUsingProvider') {
          addNotification({
            type: 'third_party_login_error_user_not_using_provider',
            meta: { provider: query.provider },
          });
        } else {
          addNotification({
            type: 'unexpected',
            meta: {
              errorId: `${query.provider} - ${query.errorType}`,
              errorType: query.errorType,
              errorMessage: query.errorMessage || '',
            },
          });
        }
        history.replace('/admin');
      } else {
        addNotification({
          type: 'unexpected',
          meta: {
            errorId: `${query.provider} - ${query.errorType || 'StatusNotOK'}`,
            errorType: query.errorType || 'StatusNotOK',
            errorMessage: query.errorMessage || '',
          },
        });
        history.replace('/admin');
      }
    },
    [history, saveAuthToken, addNotification]
  );
};
