import { decamelizeKeys } from 'humps';
import { isBoolean } from 'lodash';

import { BillingPlan } from '@/features/billing';
import {
  Workspace,
  WorkspaceAttributes,
  WorkspaceData,
} from '@/types/workspace';
import { parseAPIData } from '@/utils/parsers';

import api from './api';

export type ApiWorkspace = {
  id: number;
  name: string;
  logoUrl: string | null;
  createdAt: string;
  askInterestedInWorking: boolean;
  isSimplified: boolean;
  subscriptionPlan: BillingPlan;
  subscriptionPausedFrom: string;
  subscriptionPausedUntil: string;
  gmailEnabled: boolean;
};

export const parseWorkspace = (
  {
    id,
    name,
    logoUrl,
    createdAt,
    askInterestedInWorking,
    isSimplified,
    subscriptionPlan,
    subscriptionPausedFrom,
    subscriptionPausedUntil,
    gmailEnabled,
  }: ApiWorkspace,
  workspace?: Workspace
): Workspace => ({
  id: String(id),
  name,
  logoUrl: logoUrl || null,
  createdAt: new Date(createdAt),
  preferences: {
    askCandidatesIfInterested: askInterestedInWorking,
  },
  simplified: isBoolean(isSimplified)
    ? isSimplified
    : workspace
      ? workspace.simplified
      : true,
  subscriptionPlan,
  subscriptionPausedFrom: subscriptionPausedFrom
    ? new Date(subscriptionPausedFrom)
    : null,
  subscriptionPausedUntil: subscriptionPausedUntil
    ? new Date(subscriptionPausedUntil)
    : null,
  gmailEnabled: gmailEnabled ? gmailEnabled : false,
});

const serializeData = (data: WorkspaceData) => ({
  phone_number: data.phoneNumber,
  source: data.source,
  type_of_candidates: data.typeOfCandidates,
});

const serializeWorkspace = (
  attributes: WorkspaceAttributes,
  workspace?: Workspace
) => ({
  name: attributes.name || (workspace && workspace.name),
  logoUrl: attributes.logoUrl || (workspace && workspace.logoUrl) || null,
  askInterestedInWorking:
    attributes.askCandidatesIfInterested != null
      ? attributes.askCandidatesIfInterested
      : workspace && workspace.preferences.askCandidatesIfInterested,
  data: attributes.data != null ? serializeData(attributes.data) : undefined,
});

export async function createWorkspace(
  attributes: WorkspaceAttributes = {}
): Promise<Workspace> {
  const response = await api.post(
    `workspaces`,
    decamelizeKeys(serializeWorkspace(attributes))
  );

  const data = parseAPIData(response.data) as ApiWorkspace;
  return parseWorkspace(data);
}
